<template>
    <div class="metaDataTable">
        <el-table
            :data="paramsList"
            border
            :row-class-name="tableRowClassName"
            style="width: 100%">
            <el-table-column
                prop="desc"
                align="center"
                label="名称">
                
                <template slot-scope="scope">
                    <span>{{scope.row.desc || '--'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                align="center"
                label="标识">
                
                <template slot-scope="scope">
                    <span>{{scope.row.name || '--'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="className"
                align="center"
                label="值类型">
                <template slot-scope="scope">
                    <span>{{scope.row.className || '--'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="值">
                <template slot-scope="scope">
                    <el-select 
                        class="form_cell_select" 
                        clearable
                        v-model="scope.row.active" 
                        :disabled="notEdit"
                        @change="changeInputsOut($event, scope)">
                        <el-option
                            v-for="option in  keyValueList(scope.row.className)"
                            :key="option.id"
                            :value="option.id"
                            >
                                {{ option.id }}
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            
            <el-table-column
                v-if="elementType == 'bpmn:SubProcess'"
                align="center"
                label="	操作">
                <template slot-scope="scope">
                    <div 
                        class="table_cell_btn"
                        v-if="!scope.row.name">
                        <span class="removeOrAddVar" @click="deleteSonParma(scope.row)">删除</span>
                    </div>
                    <span v-else>--</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'metaDataTable',
    props: {
        // 出入参类型
        type: {
            type: String,
            default: 'input'
        },
        // notEdit == true 不可以编辑
        notEdit: {
            type: Boolean,
            default: true, 
        },
        // 参数表格数据
        metaList: {
            type: Array,
            default: () => []
        },
        // 子流程变量
        subProcessParams: {
            type: Array,
            default: () => []
        },

        // 流程类型
        elementType: {
            
            type: String,
            default: ''
        }
    }, 
    computed: {
        // 出入参值的列表
        keyValueList() {
            return function(val, scope) {
                
                if (!val) return [];
                let list = this.topThis.mainProcessVar;
                return list.filter(item => item.type == val)
            }
        },

    },
    inject: ['topThis'],
    watch: {
        metaList: {
            handler(val) {
                if (val) {
                    this.paramsList = JSON.parse(JSON.stringify(val));
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            paramsList: [
            ],
        }
    },
    methods: {

        /**
         * 设置table行样式
         */
        tableRowClassName({row}) {
            if (row.id) {
                return 'tableDiff'
            }
            return ''
        },

        /**
         * 监听更改
         */
        changeInputsOut(val, scope) {
            this.$set(this.paramsList, scope.$index, scope.row);
            this.$emit('change', this.paramsList)
        },

        /**
         * 删除子流程已经不存在的参数
         */
        deleteSonParma(row) {
            const num = this.paramsList.findIndex(item => item.id == row.id);
            this.paramsList.splice(num, 1);
            this.$nextTick(() => {
                
                this.$emit('change', this.paramsList)
            })
        }
    }
}
</script>

<style lang="less" scoped>

.metaDataTable {
   
    /deep/ .el-table {
        .el-table__body-wrapper {
            .el-table__body {
                .tableDiff {
                    // color: rgb(226, 154, 154);
                    .el-table__cell {
                        background-color: rgb(226, 154, 154);
                    }
                }
            }
        }
    }
}

</style>